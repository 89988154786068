import React, { useState } from "react";
import CommonBanner from "../../components/CommonBanner";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import { checkFormValidation } from "../../utils/checkValidationform";
import ValidationMessageError from "../../components/ValidationMessageError";

const JobPortal = () => {
  const initialData = {
    firstName: "",
    secondName: "",
    contact: "",
    email: "",
    address: "",
  };
  const [resume, setResume] = useState(null);
  const [formData, setFormData] = useState(initialData);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorSecondName, setErrorSecondName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);

  // const [file, setFile] = useState(null);

  const onHandleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (
        !errorFirstName &&
        !errorSecondName &&
        !errorEmail &&
        !errorContact &&
        !errorAddress
      ) {
        const data = new FormData();

        for (var key in formData) {
          data.append(key, formData[key]);
        }

        data.append("resume", resume);

        const response = await fetch(
          "https://bni-backend.onrender.com/api/resume/mail",
          {
            method: "POST",
            body: data,
          }
        );
        const getRes = await response.json();
        console.log(getRes);
        // second form send function :-
        // Add any success message or additional actions here.
      } else {
        alert("Fill The Form Completely");
      }
    } catch (error) {
      console.error("Error uploading the resume:", error);
      // Handle error and show an appropriate message to the user.
    }
  };

  // const handleOnChange = (event) => {
  //   setFile(event.target.files[0]);
  // };

  const handleOnChange = (event) => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidNumber = /^[0-9\b]+$/;

    if (event.target.name === "firstName") {
      const condition =
        event.target.value.length > 0 && event.target.value.length < 255;
      checkFormValidation([condition, setErrorFirstName]);
    }
    if (event.target.name === "secondName") {
      const condition =
        event.target.value.length > 0 && event.target.value.length < 255;
      checkFormValidation([condition, setErrorSecondName]);
    }

    if (event.target.name === "email") {
      const condition = event.target.value.match(isValidEmail);
      checkFormValidation([condition, setErrorEmail]);
    }
    if (event.target.name === "contact") {
      const condition =
        event.target.value.length === 10 &&
        event.target.value.match(isValidNumber);
      checkFormValidation([condition, setErrorContact]);
    }
    if (event.target.name === "address") {
      const condition =
        event.target.value.length > 0 && event.target.value.length < 255;
      checkFormValidation([condition, setErrorAddress]);
    }

    if (event.target.name === "resume") {
      setFormData({ ...formData, [event.target.name]: event.target.files[0] });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };
  const { firstName, secondName, contact, email, address } = formData;

  return (
    <>
      {/* <Navbar /> */}
      <CommonBanner currentPage="Jobs" heading="Jobs" />
      <iframe
        src="https://www2.jobdiva.com/portal/?a=nujdnwtvnbrzoz9kw8cxssgrc1dx3t0b21x35uo4ex7lsf7eoox85k3nmkpypgvx&compid=-1"
        title="Business Need Inc Job Portal"
        width="100%"
        height="1000"
        allowFullScreen
      ></iframe>
      <PreFooterSection />
      <Footer />
    </>
  );
};

export default JobPortal;
