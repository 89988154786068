import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer";
import PreFooterSection from "../../components/PreFooterSection";
import ServiceSection from "../../components/ServiceSection";
import { aboutBrief } from "../../constant/aboutBrief";
import BlogCarousel from "../../components/Carousel/BlogCarousel";
import { client } from "../../constant/client";
import ClientCarousel from "../../components/Carousel/ClientCarousel";
import { addAllPost } from "../../Redux/Action/userPostAction";
import { getAllPostQuery } from "../../utils/apiQuery";
import SpinLoader from "../../components/Loader/SpinLoader";
import OurBrand from "../../components/OurBrand/OurBrand";
import { Brand } from "../../constant/brand";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); // add dispatch user from store to import here
  const ourData = useSelector((state) => state.userPost.allPost);
  const [loading, setLoading] = useState(true);
  // const [show, setShow] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  //  this is for banner image

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/images/US_Banner_1.jpg",
    "/images/US_Banner_2.jpg",
    "/images/US_Banner_3.jpg",
  ];
  const aboutImage = [
    "/images/about_img_1.jpg",
    "/images/about_img_2.jpg",
    "/images/about_img_3.jpg",
    "/images/about_img_4.jpg",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change the interval duration as needed (5000 milliseconds = 5 seconds)
    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval);
  }, []);

  // const handleOnClick = () => {
  //   setShow((s) => !s);
  // };

  const fetchData = async () => {
    try {
      const response = await getAllPostQuery();
      const res = await response.json();
      if (res.success === 1) {
        if (isMounted) {
          dispatch(addAllPost(res.data));
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setIsMounted(true);
    fetchData();
    return () => {
      // Cleanup function to be called when the component is unmounted or before running the effect again
      setIsMounted(false); // Set the flag to false to indicate the component is unmounted
    };
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {/* <Navbar /> */}
      {/* {show && <ContactOverlayContainer handleOnClick={handleOnClick} />} */}
      <div
        className="home_page_banner_container"
        style={{
          backgroundImage: `linear-gradient(rgba(6, 14, 35, 0.2), rgba(6, 14, 35, 0.2)), url(${process.env.PUBLIC_URL}${images[currentImageIndex]})`,
          // backgroundImage: `linear-gradient(rgba(6, 14, 35, 0.5), rgba(6, 14, 35, 0.5)), url(${USBANNER})`,
        }}
      >
        <p className="home_banner_p_1">
          Optimizing Workforce Solutions and IT Consultancy for Business Success
        </p>
        <div className="section_3_upper_left_hr"></div>
        <p className="home_banner_p_2">
          At Business Needs Inc., we specialize in providing tailored staffing
          solutions and expert IT consultancy services across sectors such as
          healthcare, IT, professional services, and digital marketing. Our goal
          is to enhance operational efficiency, drive innovation, and support
          sustainable growth for your organization.
        </p>
        <button
          className="section_2_button"
          type="button"
          // onClick={handleOnClick}
          onClick={() => {
            navigate("/contactus");
          }}
        >
          connect now
        </button>
      </div>
      <div className="about_brief_main_container">
        {aboutBrief.map((item, i) => {
          return (
            <div
              className="about_brief_container"
              key={i}
              onClick={() => {
                navigate(item.link);
              }}
              // style={{border:"2px solid black"}}
            >
              <img
                src={item.icon}
                alt=""
                srcSet=""
                className="about_brief_container_img"
              />
              <div className="about_brief_container_title">{item.title}</div>
              <div className="about_brief_container_description">
                {item.description}
              </div>
            </div>
            // <div className="flip_card" key={i}>
            //   <div className="flip_card_inner">
            //     <div className="flip_card_front">
            //       <div className="section_3_card_icon">
            //         <img
            //           src={item.icon}
            //           alt=""
            //           srcSet=""
            //           style={{ width: "100%" }}
            //         />
            //       </div>
            //       <div className="section_3_card_title">{item.title}</div>
            //       <div
            //         className="section_3_card_description"
            //         style={{ width: "90%" }}
            //       >
            //         {item.description}
            //       </div>
            //     </div>
            //   </div>
            // </div> 
          );
        })}
      </div>
      <div className="section_2">
        <div className="section_2_about_banner_container">
          <div className="section_2_group_top_image">
            <div className="section_2_group_top_left_image">
              <img
                src={`${process.env.PUBLIC_URL}${aboutImage[1]}`}
                alt=""
                srcSet=""
                className="section_2_group_image"
              />
            </div>
            <div className="section_2_group_top_right_image">
              <img
                src={`${process.env.PUBLIC_URL}${aboutImage[0]}`}
                alt=""
                srcSet=""
                className="section_2_group_image"
              />
            </div>
          </div>
          <div className="section_2_group_bottom_image">
            <div className="section_2_group_bottom_left_image">
              <img
                src={`${process.env.PUBLIC_URL}${aboutImage[2]}`}
                alt=""
                srcSet=""
                className="section_2_group_image"
              />
            </div>
            <div className="section_2_group_bottom_right_image">
              <img
                src={`${process.env.PUBLIC_URL}${aboutImage[3]}`}
                alt=""
                srcSet=""
                className="section_2_group_image"
              />
            </div>
          </div>
        </div>
        <div className="section_2_about_text_container">
          <div className="section_2_p_1">
            <div className="triangle"></div>
            ABOUT BUSINESS NEEDS INC.
          </div>
          <div className="section_2_p_2">
            Dependable Technology Support and Solutions
          </div>
          <div className="section_2_p_3">
            At Business Needs Inc., we prioritize our clients and understand
            that every business has unique professional needs. Our team is
            dedicated to delivering high-quality and reliable professional
            solutions to ensure your business runs smoothly and efficiently.
            With our comprehensive managed IT services and end-to-end solutions,
            you can streamline your operations, increase efficiency, and
            optimize your revenue potential.
          </div>
          <button
            className="section_2_button"
            type="button"
            onClick={() => navigate("/about")}
          >
            Read More
            <img
              src="https://cdn-icons-png.flaticon.com/512/271/271226.png"
              alt=""
              srcSet=""
              width="10px"
              height="10px"
              style={{ filter: "invert(1)" }}
            />
          </button>
        </div>
      </div>
      <ServiceSection />
      <div className="section_4">
        <div className="section_4_heading" style={{ justifyContent: "center" }}>
          <div className="triangle"></div>
          NEWS & BLOG
        </div>
        <div className="section_4_title">RECENT NEWS & EVENTS</div>
        <div className="section_4_carousel">
          {loading ? <SpinLoader /> : <BlogCarousel post={ourData} />}
        </div>
      </div>
      <div className="section_5">
        <div className="section_5_heading" style={{ justifyContent: "center" }}>
          <div className="triangle"></div>
          CLIENT & CUSTOMER
        </div>
        <div className="section_5_title">OUR GOLDEN CLIENTS</div>
        <div className="section_5_carousel">
          <ClientCarousel client={client} />
        </div>
      </div>
      <div className="section_5">
        <div className="section_5_title">OUR BRANDS</div>
        <div className="section_5_carousel">
          <OurBrand brand={Brand} />
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default Home;
